import React, { createRef } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { SnackbarProvider } from "notistack";
import { BrowserRouter } from "react-router-dom";
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { onError } from "@apollo/client/link/error";

Sentry.init({
  dsn: "https://920413ab37a448ea8a61f5f27fdc3e39@o4505028684808192.ingest.sentry.io/4505029066948608",
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      sessionStorage.setItem("error", message);
    });
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    sessionStorage.setItem("network", networkError);
  }
});

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_BASE_URL,
});

const client = new ApolloClient({
  uri: process.env.REACT_APP_API_BASE_URL,
  cache: new InMemoryCache(),
  link: errorLink.concat(httpLink),
});

// add action to all snackbars
const notistackRef = createRef();
const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key);
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <SnackbarProvider
      ref={notistackRef}
      maxSnack={3}
      action={(key) => (
        <button
          onClick={onClickDismiss(key)}
          className="text-white font-semibold text-sm"
          style={{
            cursor: "pointer",
          }}
        >
          Dismiss
        </button>
      )}
    >
      <BrowserRouter>
        <ApolloProvider client={client}>
          <App />
        </ApolloProvider>
      </BrowserRouter>
    </SnackbarProvider>
  </React.StrictMode>
);

