import { createContext, useEffect } from "react";
import "./App.css";
import { useState } from "react";
import { theme } from "./muitheme";
import * as Sentry from "@sentry/react";
import { Home } from "./components/Home";
import { ThemeProvider } from "@mui/material";
import { Route, Routes } from "react-router-dom";

export const StepContext = createContext(null);
export const AppContext = createContext(null);

function App() {
     
     const [step, setStep] = useState(1);
     const [token, setToken] = useState(null);
     const [plans, setPlans] = useState(undefined);
     const [loading, setLoading] = useState(false);
     const [showForm, setShowForm] = useState(false);
     const [doctorInfo, setDoctorInfo] = useState(false);
     const [partnerInfo, setPartnerInfo] = useState(null);

     function clearCacheStorage() {
          caches.keys().then(function (names) {
               for (let name of names) {
                    caches.delete(name);
                    window.location.reload();
               }
          });
     }

     clearCacheStorage();

     useEffect(() => {
          if (!partnerInfo) return;
          sessionStorage.setItem("partnerInfo", JSON.stringify(partnerInfo));
     }, [partnerInfo]);

     useEffect(() => {
          const storedToken = sessionStorage.getItem("token");
          if (!token && storedToken) {
               setToken(storedToken);
          }
          if (token) {
               sessionStorage.setItem("token", token);
               localStorage.setItem("token", token);
          }
          // eslint-disable-next-line react-hooks/exhaustive-deps
     }, []);

     useEffect(() => {
          if (token) {
               sessionStorage.setItem("token", token);
               localStorage.setItem("token", token);
          }
          // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [token]);

     useEffect(() => {
          const step = sessionStorage.getItem("currentStep");
          const showForm = sessionStorage.getItem("showForm");
          if (step) setStep(Number(step));
          if (showForm) setShowForm(JSON.parse(showForm));
     }, []);

     return (
          <ThemeProvider theme={theme}>
               <AppContext.Provider
                    value={{
                         plans,
                         token,
                         loading,
                         setToken,
                         setPlans,
                         showForm,
                         setLoading,
                         doctorInfo,
                         partnerInfo,
                         setShowForm,
                         setDoctorInfo,
                         setPartnerInfo,
                    }}
               >
                    <StepContext.Provider value={{ step, setStep }}>
                         <Routes>
                              <Route path="/" element={<Home />} />
                              <Route path="/:subdomain" element={<Home />} />
                         </Routes>
                    </StepContext.Provider>
               </AppContext.Provider>
          </ThemeProvider>
     );
}

export default Sentry.withProfiler(App);
