import { gql } from "@apollo/client";

export const GET_PARTNER_BY_SUBDOMAIN = gql`
  query getPartnerBySubdomain($subdomain: String!) {
    getPartnerBySubdomain(subdomain: $subdomain) {
      subdomain
      providerId
      widgetColor
      widgetLogo
      apiKey
      category
    }
  }
`;

export const GET_SERVER_TIME = gql`
query getServerTime {
  getServerTime {
    time
    timeLagos
    timeJSdate
    timeLagosJsDate
    hourMin
    hourMinLagos
  }
}
`;


export const GET_USER_TYPES = gql`
  query getUserTypes {
    getUserTypes {
      userType {
        _id
        name
        icon
        description
        providerCount
      }
    }
  }
`
export const DOCTOR_PROFILE = gql`
  query doctorProfile($id: ID!) {
    doctorProfile(id: $id) {
      _id
      firstName
      lastName
      gender
      phoneNumber
      createdAt
      updatedAt
      email
      rating
      hospital
      specialization
      dob
      cadre
      picture
      provider
      completedConsultations
      cancelledConsultations
      failedConsultations
      totalConsultationHours
      averageConsultationHours
      consultations
      status
      fee
      dociId
      providerId
      accountDetails {
        accountName
        accountNumber
        bankName
      }
    }
  }
`

export const FIND_PROFILES = gql`
  query findProfiles($dociId: String!) {
    profiles(search:$dociId) {
      data {
        _id
        firstName
        lastName
        height
        weight
        bloodGroup
        genotype
        gender
        phoneNumber
        provider
        plan
        status
        consultations
        createdAt
        image
        rating
        pastIllness {
          id
        }
        providerId
        accountData{
          email
        }
      }
    }
  }
`

export const GET_PLANS = gql`
  query getPlans($id: String!) {
    getPlans(user: $id) {
      plan {
        _id
        name
        amount
        description
        createdAt
        updatedAt
        provider
        providerData
        duration
        subscribed
      }
    }
  }
`