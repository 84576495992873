import axios from "axios";
import { useContext } from "react";
import { AppContext } from "../../App";

export const useFetchAPI = () => {
  const BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const { token } = useContext(AppContext);
  const storedToken = localStorage.getItem("apiKey");
  const storedKey = localStorage.getItem("token");

  const base = axios.create({
    baseURL: BASE_URL + "rest/",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Api-Key ${token || storedToken === 'undefined' ? storedKey : storedToken}`,
      Accept: "application/json",
    },
  });

  const GET_PLANS = (providerId, apiKey) =>
    base.get(`plans?filterBy[provider]=${providerId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Api-Key ${apiKey}`,
        Accept: "application/json",
      },
    });
    
  return {
    GET_PLANS,
  };
};
